import React from "react";
import "./App.css";
import RegistrationForm from "./components/RegistrationForm";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ErrorPage from "./components/error";
import CompletedRegistration from "./components/completedRegistration";
// import RegistrationForm from "./components/RegistrationForm";


function App() {
  return (
    <Router>
        <div className="App">
          {/* <Header /> */}
          <Routes>
            <Route path="/:n" element={<RegistrationForm />} />
            <Route path="/error" element={<ErrorPage />} />  {/* Include the "n" parameter */}
            {/* Define routes for other components */}
            <Route path="/success" element={<CompletedRegistration />} />
          </Routes>
          {/* <Footer /> */}
        </div>
      </Router>
  );
}

export default App;
